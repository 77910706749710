import React, {useEffect, useState} from "react";
import Layout from "../components/layout";
import 'animation.gsap'
import 'debug.addIndicators'
import { graphql} from "gatsby"
import '../resources/styles/pages/_website-development.scss'
import Subgrid from "../components/subgrid/index";
import Utils from "../utils/utils";
import CodicateImage from "../components/codicate-image/index";
import TransitionButton from "../components/transition-button/index";
import ScrollMagic from "scrollmagic";
const TweenMax = require('TweenMax');


const Slide = ({index, className, setSlide, projectsData}) => {

    return (
        <TransitionButton
            // to={`/${projectsData[index].name}`}
            to={projectsData[index].path}
            pageBackground="toBlueBg"
        >
            <div className={`project-name-num-container project-name-${index + 1} ${className}`}
                 onMouseEnter={() => {setSlide(index); }}
                 style={{height: `auto`}}
            >
                <span className="section-number">{Utils.numFormetter(index + 1)}</span>
                <div className="project-name">{projectsData[index].name}</div>
            </div>
        </TransitionButton>
    );
}

const ProjectsPage = ({data}) => {

    const {allWordpressPage, wordpressPage} = data;

    const [slide, setSlide] = useState(0);
    const [isProjectListHover, setIsProjectListHover] = useState(false);
    const [imagePreload, setImagePreload] = useState([]);

    const projectsData = allWordpressPage.edges.map(edge => (
        {
            name: edge.node.title.toLowerCase(),
            image: edge.node.acf.projectImage ? edge.node.acf.projectImage.localFile : null,
            path: edge.node.path,
            wordpressId: edge.node.wordpressId
        }
    ));

    let sortedProjectsData = [];

    wordpressPage.acf.projectsMenuOrder.forEach((item) => {
        const orderedItem = projectsData.filter(node => node.wordpressId === item.wordpressId)[0];
        return sortedProjectsData = [...sortedProjectsData, orderedItem]
    });


    // const projectsImagesPreload = projectsData.map(project => {
    //     const image = new Image();
    //     if(project.image) {
    //         image.src = project.image
    //     }
    //     return image;
    // });

    const sectionsTiming = projectsData.map((project, index) => {
        return {
            name: `section-${index + 1}`,
            duration: Utils.calcDuration(0.125),
        }
    });

    const projectsListMouseEnter = () =>  setIsProjectListHover(true);
    const projectsListMouseLeave = () =>  setIsProjectListHover(false);

    const onScrollProjectsProgress = (event) => {
        if(!isProjectListHover){

            setSlide(Math.round(event.progress * (projectsData.length-1)));
        }
    };

    /**
     * Image preload effect hook
     */
    useEffect(() => {
        const images = sortedProjectsData.map(project => {
            const image = new Image();
            if(project.image && project.image) {
                image.src = project.image.childImageSharp.fluid.src;

                return project.image;
            }
            return null;
        });
        setImagePreload(images);
    }, [])

    useEffect(() => {

        const controller = new ScrollMagic.Controller();
        const scene = [];

        scene.push(new ScrollMagic.Scene({offset: 0, duration: document.querySelector('.main-container').offsetHeight - window.innerHeight })
            .setTween('.projectsList', {y: '-90%', ease: TweenMax.Linear.easeNone})
            // .addIndicators({name: 'menu on footer'}) // add indicators
            .on("progress", onScrollProjectsProgress)
            .addTo(controller));


        return () => {
            //componentDidUnMount

            scene.forEach(function (el) {
                el.destroy(true);
                el = null;
            });
        }
    }, [isProjectListHover]);

    return (
        <Layout className="page-projects blueBg" isIncludeFooter={false}
                hasGrisSquares={false}
                yoastData={data.wordpressPage.yoast || null}
                seoTitle={data.wordpressPage.acf.seoTitle}
                pageTitle={data.wordpressPage.title}
        >
            <Subgrid
                // imagesSlides={projectsData.map((project) => {
                //     return ({
                //         content: <CodicateImage img={project.image} />
                //     })
                // })}
                hasArrow={false}
                imagesSlides={[
                    {
                        content: <div className="image-container">
                            <CodicateImage
                                img={imagePreload[slide]}
                                className={`projectImage mask${((slide % 3) + 1)}`}
                            />
                            <CodicateImage
                                img={data.projectsSquers}
                                className="squares-image"
                            />
                        </div>
                    }
                ]}
                textSlides={
                    [{
                        content:
                            <ul className="projectsList"
                                onMouseEnter={projectsListMouseEnter} onMouseLeave={projectsListMouseLeave}
                            >

                                {projectsData.map((card, index) => (
                                    <li key={index}>
                                        <Slide index={index} card={card.name}
                                               setSlide={setSlide}
                                               projectsData={sortedProjectsData}
                                               className={slide === index ? 'active' : ''}
                                               sectionsTiming={sectionsTiming}
                                    />
                                    </li>
                                ))
                                }
                            </ul>
                    }]
                }

            />
        </Layout>
    )
};


export default ProjectsPage


export const query = graphql`
    query {
        allWordpressPage(filter: {template: {eq: "projects-inner.php"}}){
            edges {
                node {
                    wordpressId: wordpress_id
                    title
                    path
                    acf {
                        projectImage: project_page_image {
                            localFile {
                                publicURL
                                childImageSharp {
                                    fluid(maxWidth: 1000){
                                        ...GatsbyImageSharpFluid_withWebp_noBase64
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        wordpressPage(template: {eq: "projects.php"}){
            title
            path
            ...YoastData
            acf {
                seoTitle: seo_title
                projectsMenuOrder: projects_menu_order {
                    wordpressId: wordpress_id
                    postTitle: post_title
                }
            }
        }
        projectsSquers: file(relativePath: { eq: "projects/projects-squers.png"}) {
            childImageSharp {
                fluid(maxWidth: 1000){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`;